/*
 * @Descripttion:
 * @version: 0.0.1
 * @Author: 小妖怪
 * @Date: 2020-07-01 11:28:41
 * @LastEditors: Mr.小妖怪
 * @LastEditTime: 2020-07-08 19:41:00
 */
// bar


// bar  end

const provinces = ['shanghai', 'hebei', 'shanxi', 'neimenggu', 'liaoning', 'jilin', 'heilongjiang', 'jiangsu', 'zhejiang', 'anhui', 'fujian', 'jiangxi', 'shandong', 'henan', 'hubei', 'hunan', 'guangdong', 'guangxi', 'hainan', 'sichuan', 'guizhou', 'yunnan', 'xizang', 'shanxi1', 'gansu', 'qinghai', 'ningxia', 'xinjiang', 'beijing', 'tianjin', 'chongqing', 'xianggang', 'aomen']

const provincesText = ['上海', '河北', '山西', '内蒙古', '辽宁', '吉林', '黑龙江', '江苏', '浙江', '安徽', '福建', '江西', '山东', '河南', '湖北', '湖南', '广东', '广西', '海南', '四川', '贵州', '云南', '西藏', '陕西', '甘肃', '青海', '宁夏', '新疆', '北京', '天津', '重庆', '香港', '澳门']

const seriesData = []
function initEcharts(province_en, provinces_zh) {
  const _this = this
  var tmpSeriesData = province_en === 'china' ? seriesData : []
  const option = {
    title: {
      show: false,
      text: provinces_zh || province_en,
      left: 'center'
    },
    tooltip: {
      show: true,
      trigger: 'item'
    },
    geo: {
      map: province_en,
      aspectScale: 0.75,
      label: {
        emphasis: {
          show: false
        }
      },
      layoutCenter: ['50%', '50%'],
      // 如果宽高比大于 1 则宽度为 100，如果小于 1 则高度为 100，保证了不超过 100x100 的区域
      layoutSize: '125%',
      tooltip: {
        show: true
      },
      label: {
        normal: {
          show: true, // 显示省份标签
          textStyle: { color: '#fbfdfe' }// 省份标签字体颜色
        },
        emphasis: { // 对应的鼠标悬浮效果
          show: false,
          textStyle: { color: '#323232' }
        }
      },
      itemStyle: {
        normal: {
          borderColor: '#ffbf54',
          borderWidth: 1,
          areaColor: {
            type: 'linear-gradient',
            x: 1000,
            y: 350,
            x2: 1000,
            y2: 0,
            colorStops: [{
              offset: 0,
              color: '#071540' // 50% 处的颜色
            }, {
              offset: 1,
              color: '#00a1f6' // 0% 处的颜色
            }],
            global: true // 缺省为 false
          }
        },
        emphasis: {
          show: false,
          areaColor: '#2cb3dd',
          textStyle: { color: '#fff' }
        }
      }
    },
    series: [
      {
        type: 'effectScatter',
        coordinateSystem: 'geo',
        rippleEffect: {
          period: 15,
          scale: 4,
          brushType: 'fill'
        },
        label: {
          formatter: '{b}',
          position: 'right',
          show: false
        },
        tooltip: {
          formatter: function ({ data }) {
            return data.name + ": " + data.value[2];
          }
        },
        itemStyle: {
          normal: {
            color: '#b9be23',
            shadowBlur: 10,
            shadowColor: '#333'
          }
        },
        data: this.brandMap
      }

    ]
  }
  setTimeout(() => {
    this.myChart.setOption(option)
  }, 1200);
  // this.myChart.off('click')
  // setTimeout(() => {
  //   if (province_en === 'china') { // 全国时，添加click 进入省级
  //     this.myChart.on('click', function (param) {
  //       // 遍历取到provincesText 中的下标  去拿到对应的省js
  //       for (var i = 0; i < provincesText.length; i++) {
  //         if (param.name === provincesText[i]) {
  //           // 显示对应省份的方法
  //           _this.showProvince(provinces[i], provincesText[i])
  //           break
  //         }
  //       }
  //       //   if (param.componentType === 'series') {
  //       //     var provinceName = param.name
  //       //     $('#box').css('display', 'block')
  //       //     $('#box-title').html(provinceName)
  //       //   }
  //     })
  //   } else { // 省份，添加双击 回退到全国
  //     this.myChart.on('dblclick', function () {
  //       _this.initEcharts('china', '中国')
  //     })
  //   }
  // }, 1000);
}
export default {
  provinces,
  provincesText,
  seriesData,
  initEcharts
}
// const mapDate = [
//   {
//     name: '北京',
//     value: [116.4551, 40.2539, 4578]
//   },
//   {
//     name: '成都',
//     value: [103.9526, 30.7617, 29999]
//   },
//   {
//     name: '军魂网',
//     value: [125.9526, 78, 29999]
//   },
//   {
//     name: '云南',
//     value: [102.9199, 25.4663, 29999]
//   },
//   {
//     name: '西藏',
//     value: [91.1865, 30.1465]
//   },
//   {
//     name: '广州',
//     value: [120.5107, 23.2196]
//   },
//   {
//     name: '黑龙江',
//     value: [127.9688, 45.368, 29999]
//   }
// ]

<template>
  <div class="home" >
    <ul class="flexContent flex">
      <li class="leftData flex flex-column">
        <!-- 取电房间数 -->
        <div class="takeNumber bk_b">
          <header>取电房间数量</header>
          <div class="electricity">
            <div class="take_ins">
              <!-- <div> -->
                <span>内部取电</span>
              <!-- </div> -->
              <div class="Num">
                <count-to
                  :start-val="initElectricity.manage"
                  :end-val="roomElectricity.manage"
                  :duration="3000"
                />
              </div>
            </div>
            <div class="take_total">
              <div>
                <span>取电总数</span>
              </div>
              <div class="Num">
                <count-to :start-val="initTotal" :end-val="total" :duration="3000" />
              </div>
            </div>
          </div>
          <div class="customer flex flex-column">
            <span style="font-size:2vh;line-height: 3.5vh;">客人取电</span>
            <count-to
              style="font-size:3.5vh"
              :start-val="initElectricity.guest"
              :end-val="roomElectricity.guest"
              :duration="3000"
            />
          </div>
        </div>
        <!-- sos报警 -->
        <div class="sosAlarm bk_b">
          <div class="alarmCont">
            <div class="listheader" style="line-height:3.2vh">
              <h5>SOS报警</h5>
              <span>合计：{{storesTotal}}</span>
            </div>
            <div class="alarm_panel">
              <div v-if="sos" class="listcount">
                <template v-if="stores.length">
                  <div
                    v-for="(storesItem,index) in stores"
                    :key="index"
                    class="list"
                    @click="storesClick(storesItem)"
                  >
                    <p>{{storesItem.hotelName}}</p>
                    <!-- <el-badge :value="storesItem.count+99" :max="99" >
                    </el-badge> -->
                    <span>{{storesItem.count}}</span>
                  </div>
                </template>
                <template v-else>
                  <div class="noData flex flex-center align-center">暂无 SOS 报警</div>
                </template>
              </div>
              <div v-else class="storesDetails">
                <div class="storesName">{{storesName}}</div>
                <div class="detailsListCont" v-loading="sosFlag">
                  <!-- <img src="../../assets/loading.gif" alt="" v-if="sosFlag"> -->
                  <div v-if="!sosFlag">
                    <div
                      v-for="(roomItem,index) in roomList"
                      :key="index"
                      @click="sos=true;"
                    >{{roomItem.floorName}}{{roomItem.roomNumber}}房</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 设备离线报警 -->
        <div class="sosAlarm bk_b">
          <div class="alarmCont">
            <div class="listheader" style="line-height:3.2vh">
              <h5>设备离线报警</h5>
              <span>合计：{{offlineStoresTotal}}</span>
            </div>

            <div class="alarm_panel">
              <div v-if="offline" class="ListCont">
                <template v-if="offlineStores.length">
                  <div
                    v-for="(offlineItem,index) in offlineStores"
                    :key="index"
                    @click="offlineClick(offlineItem)"
                    class="list"
                  >
                    <p>{{offlineItem.hotelName}}</p>
                    <span>{{offlineItem.count}}</span>
                  </div>
                </template>
                <template v-else>
                  <span class="noData flex flex-center align-center">暂无 设备离线 报警</span>
                </template>
              </div>
              <div v-else class="storesDetails">
                <div class="storesName">{{offlineName}}</div>
                <div class="detailsListCont" v-loading="offlineFlag">
                  <!-- <img src="../../assets/loading.gif" alt="" v-if="offlineFlag"> -->
                  <div v-if="!offlineFlag">
                    <div
                      v-for="(roomItem,index) in offlineRoomList"
                      :key="index"
                      @click="offline=true"
                    >{{roomItem.floorName}}{{roomItem.roomNumber}}房</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="degreeCont">
          <div class="temperature">
            <p>温度</p>
            <div class="temperatureList">
              <h4 v-for="(item,index) in list" :key="index" :class="index>15?red:''"></h4>
            </div>
          </div>
          <div class="temperature" style="margin-top:15px;">
            <p>湿度</p>
            <div class="temperatureList">
              <h4 v-for="(item,index) in list" :key="index" :class="index>15?red:''"></h4>
            </div>
          </div>
          <div class="temperature" style="margin-top:15px;">
            <p>甲醛</p>
            <div class="temperatureList">
              <h4 v-for="(item,index) in list" :key="index" :class="index>15?red:''"></h4>
            </div>
          </div>
        </div>-->
      </li>
     
      <li class="eachartsCont "> <!-- flex flex-column flex-1 -->
        <div id="myChart"></div>
       
        <div class=" bar_charts"> <!-- flex-1 -->
          <div class="flex bar_tool flex-between">
            <a class="shop">分店数 {{brandRoom.sumBranchNumber}}</a>
            <a class="room">客房数 {{brandRoom.sumRoomNumber}}</a>
          </div>
          <bar :list="brandRoom.brandRegionNumberVos" />
        </div>
      </li>
      <li class="rightData flex flex-column">
        <!-- 设备出租率 -->
        <div class="probability bk_b">
          <div class="title">
            <div class="rent">
              <div class="pmsFont">
                <span>设备出租率</span>
              </div>
              <div class="countNum"> 
                <count-to
                  style="color:#00CC00"
                  :start-val="initDevicePMS.device"
                  :end-val="devicePMS.device||0"
                  :duration="3000"
                  suffix="%"
                />
              </div>
            </div>
            <div style=" background: linear-gradient(to right, #fe7b54 30%, #bf1d13);">
              <div class="pmsFont">
                <span>PMS出率</span>
              </div>
              <div class="countNum">
                <count-to
                  style="color:yellow"
                  suffix="%"
                  :start-val="initDevicePMS.pms"
                  :end-val="devicePMS.pms||0"
                  :duration="3000"
                />
              </div>
            </div>
          </div>
          <!-- <header>月出租率与 PMS出率</header> -->
          <div class="line">
            <div id="lineEcharts"></div>
          </div>
        </div>
        <!-- 分店入驻情况 -->
        <!-- <div class="enterDetails flex-1">
                <header>分店入驻情况</header>
                <div class="sorting">
                    <span class="cursor_pointer tab" @click="onSort()" :class="{'selected':sort==='asc'}">TOP</span>
                    <span class="cursor_pointer tab" @click="onSort(1)" :class="{'selected':sort==='desc'}">BOTTOM</span>
                </div>
                <div class="alarm_panel">
                    <div v-for="i in 20" :key="i" class="enterList">
                        <p>广州番禺店</p>
                        <span>80%</span>
                    </div>
                </div>
        </div>-->
        <!-- 出租率情况 -->
        <div class="enterDetails flex-1 bk_b">
          <div class="enterDetalis_title">
            出租率情况
            <!-- <h4>出租率情况</h4> -->
          </div>
          <div class="flex flex-between">
            <!-- 设备出租率 -->
            <div class="flex flex-column">
              <span class="btn">设备出租率</span>
              <div class="flex enter_btn flex-center">
                <div
                  class="asc"
                  :class="{'active':condition==='equipmentOccupancyRate' && order ==='asc'}"
                  @click="getOccupancyInfo({condition:'equipmentOccupancyRate',order:'asc'})"
                ></div>
                <div
                  class="desc"
                  :class="{'active':condition==='equipmentOccupancyRate' && order ==='desc'}"
                  @click="getOccupancyInfo({condition:'equipmentOccupancyRate',order:'desc'})"
                ></div>
              </div>
            </div>
            <div class="flex flex-column">
              <span class="btn">PMS出租率</span>
              <div class="flex enter_btn flex-center">
                <div
                  class="asc"
                  :class="{'active':condition==='pmsOccupancyRate' && order ==='asc'}"
                  @click="getOccupancyInfo({condition:'pmsOccupancyRate',order:'asc'})"
                ></div>
                <div
                  class="desc"
                  :class="{'active':condition==='pmsOccupancyRate' && order ==='desc'}"
                  @click="getOccupancyInfo({condition:'pmsOccupancyRate',order:'desc'})"
                ></div>
              </div>
            </div>
          </div>
          <div v-if="occupancyInfo.length" class="enter_panel flex-1">
            <div v-for="(info,index) in occupancyInfo" :key="index" class="flex enter_cell">
              <div class="exter_facility">{{info.equipmentOccupancyRate}} %</div>
              <div class="exter_shop flex-1 text-center">{{info.hotelName}}</div>
              <div class="exter_PMS">{{info.pmsOccupancyRate}} %</div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import CountTo from "vue-count-to";
import "echarts/map/js/china.js";
import config from "./common/config";
import lineConfig from "./common/line";
import bar from "./components/horizontalBar/index";
import {
  GET_BRAND_MAP,
  GET_SEVEN_PMS,
  GET_OCCUPANCY_INFO,
  GET_ROOM_ELECTRICITY,
  GET_BRAND_ROOM,
  GET_SosStores,
  GET_Room,
  GET_Offlinesotres,
  GET_OfflinRoom,
} from "@/api";
export default {
  // name: "Home",
  components: {
    bar,
    CountTo,
  },
  computed: {
    total() {
      const val = this.roomElectricity.guest + this.roomElectricity.manage;
      return isNaN(val) ? 0 : val;
    },
    initTotal() {
       const val = this.initElectricity.guest + this.initElectricity.manage;
       return isNaN(val) ? 0 : val;
    },
    storesTotal(){
         return this.stores.reduce(function(sum, item) {
           let val=item.count ?item.count: 0
            return sum +  val ;
          }, 0);
    },
    offlineStoresTotal(){
       return this.offlineStores.reduce(function(sum, item){
        let val=item.count ?item.count: 0
            return sum +  val ;
          }, 0);
    }
  },
  data() {
    return {
      red: "red",
      sos: true,
      offline: true,
      sort: "asc",
      areaNum: 1,
      rentVal: 22,
      brandMap: [],
      brandRoom: {},
      roomElectricity: {
        guest: 0,
        manage: 0,
      },
      initElectricity: {
        guest: 0,
        manage: 0,
      },
      sevenPms: {
        dayRented: [],
        dayPMS: [],
        day: [],
      },
      // 出租率情况
      occupancyInfo: [],
      condition: "pmsOccupancyRate",
      order: "asc",
      stores: [], //sos门店
      storesName: "", //门店名
      roomList: [],
      offlineStores: [],
      offlineName: "",
      offlineRoomList: [],
      sosFlag: false,
      offlineFlag: false,
      initDevicePMS: {
        device: 0,
        pms: 0,
      },
      devicePMS: {
        device: 0,
        pms: 0,
      },
    };
  },
  mounted() {
 
    this.myChart = this.$echarts.init(document.getElementById("myChart"));
    this.lineEcharts = this.$echarts.init(
      document.getElementById("lineEcharts")
    );
    window.addEventListener("resize", () => {
      this.myChart.resize();
      this.lineEcharts.resize();
    });
    this.init();
  },
  async beforeCreate() {
    // 地理坐标
    const res = await GET_BRAND_MAP();
    res.data.data.forEach((item) => {
      this.brandMap.push({
        name: item.cityName,
        value: [+item.lng, +item.lat, +item.value],
      });
    });
    // 每 10s 刷新一次接口
    function init() {
      // 获取分店数及房间数
      this.getBrandRoom();
      // 获取取电总数
      this.getElectricity();
      //sos报警门店列表
      this.getSosStores();
      //设备离线
      this.getOffline();
      // 七日设备出租率情况
      this.getSevenPms();
      // 获取设备出租情况
      this.getOccupancyInfo();
    }
    init.call(this);
   this.setTime= setInterval(() => {
      // 获取地理坐标
      this.getMapData();
      init.call(this);
    }, 10000);
  },
  destroyed() {
     window.clearInterval(this.setTime)
  },
  methods: {
    async getMapData() {
      this.brandMap = [];
      // 地理坐标
      const res = await GET_BRAND_MAP();
      res.data.data.forEach((item) => {
        this.brandMap.push({
          name: item.cityName,
          value: [+item.lng, +item.lat, +item.value],
        });
      });
    },
    // 获取七日内的pms 出租率情况
    async getSevenPms(data = {roleType:0}) {
      ["dayRented", "dayPMS", "day"].forEach((item) => {
        this.$set(this.sevenPms, item, []);
      });
      const res = await GET_SEVEN_PMS(data);
      console.log(res.data.data,'-------------------pms');
      res.data.data.forEach((item) => {
        this.sevenPms.dayRented.unshift(item.deviceRentedCount);
        this.sevenPms.dayPMS.unshift(item.occupancyRate);
        this.sevenPms.day.unshift(item.createTime);
      });
      this.sevenPms.day.pop();
      this.devicePMS.pms = this.sevenPms.dayPMS.pop();
      this.devicePMS.device = this.sevenPms.dayRented.pop();
      setTimeout(() => {
        this.initDevicePMS = this.devicePMS;
      }, 2000);

      this.initLine();
      // this.sevenPms = res.data.data
    },
    // 获取出租率情况
    async getOccupancyInfo(
      data = {
        condition: "equipmentOccupancyRate", order: "desc"
      }
    ) {
      const res = await GET_OCCUPANCY_INFO(data);
      this.occupancyInfo = res.data.data;
      this.condition = data.condition;
      this.order = data.order;
    },
    // 获取取电总数
    async getElectricity(data={roleType:0}) {
      const res = await GET_ROOM_ELECTRICITY(data);
      this.roomElectricity = res.data.data;
      setTimeout(() => {
        this.initElectricity = res.data.data;
      }, 3000);
    },
    // 获取分店数及房间数数据
    async getBrandRoom(data) {
      const res = await GET_BRAND_ROOM(data);
      this.brandRoom = res.data.data;
    },
    // 获取分店 地图
    async getBrandMap(data) {
      const res = GET_BRAND_MAP(data);
      this.brandMap = res.data.data;
    },
    init() {
      this.initEcharts("china", "中国", this.brandMap);
    },
    initLine() {
      this.lineEcharts.setOption(lineConfig.getOptions(this.sevenPms));
    },
    // 排序
    onSort(way) {
      this.sort = way ? "desc" : "asc";
    },
    // bar 客房数跟分店数
    onBar(way) {
      this.areaNum = way;
    },
    initEcharts: config.initEcharts,
    loadProvince() {},
    async loadSource(url, cbk) {
      await require(`echarts/map/js/province/${url}.js`);
      cbk();
    },
    showProvince(province, provinceTxt) {
      this.loadSource(province, () => {
        this.initEcharts(provinceTxt, provinceTxt);
      });
    },
    //sos报警门店
    async getSosStores(data) {
      const res = await GET_SosStores(data);

      this.stores = res.data.data;
    },
    // 点击报警门店事件
    storesClick(item) {
      this.sos = false;
      this.sosFlag = true;
      this.storesName = item.hotelName;
      this.getSosRoom(item);
    },
    //sos报警房间
    async getSosRoom(data) {
      const res = await GET_Room(data);
      this.roomList = res.data.data;
      this.sosFlag = false;
    },
    async getOffline(data) {
      const res = await GET_Offlinesotres(data);
      this.offlineStores = res.data.data;
    },
    //设备离线点击事件
    offlineClick(item) {
      this.offline = false;
      this.offlineFlag = true;
      this.offlineName = item.hotelName;
      this.getofflineRoom(item);
    },
    async getofflineRoom(data) {
      const res = await GET_OfflinRoom(data);
      this.offlineFlag = false;
      this.offlineRoomList = res.data.data;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/mdKanban/index.scss";
.home{
  width: 100%;
  height: 100%;
}

.noData {
  color: #aaa;
  height: 100%;
  font-size: 0.8vw;
}
.listcount {
  width: 100%;
  min-height: 80%;
}
#myChart {
  width: 100%;
  height: 68%;
  margin: auto;
}
.bar_charts {
  height: 30%;
  // overflow: hidden;
  box-sizing: border-box;
 
}

// #bar_horizontal {
//   height: 25vh;
// }

#box-title {
  display: block;
}
.tab {
  margin-right: 0.95vw;
  &.selected {
    color: skyblue;
  }
}
.bar_tool {
  padding: 0 10%;
  a {
    padding: 0 0.4vw;
    outline: none;
    // background: #fff;
    color: #000;
    font-size: 1.6vh;
    line-height: 3vh;
    height: 3vh;
    border-radius: 0.15vw;
    cursor: pointer;
  }
  .shop {
    background: #caf982;
  }
  .room {
    background: #f59a23;
  }
}

.alarm_panel {
  height: calc(100% - 1.5vw);
  padding: 0.2vw 0;
  box-sizing: border-box;
  overflow-y: auto;
}
//   设备出租
.rent {
  background: linear-gradient(to right, #3f83d1 30%, #4693eb);
}
.flexContent {
  display: flex;
  justify-content: flex-start;
  background: url("../../../assets/img/backg.png") no-repeat left top;
  background-size: 100% 100%;
  background-attachment: fixed;
  background-clip: content-box;
  height: 100%;
  li {
    width: 16%;
    min-width: 16%;
  }
  .eachartsCont {
    flex: 1;
    // width: 70%;
    height: 100%;
  }

  .leftData .rightData {
    // height: 90vh;
    padding: 1.6vw 0 0 31.6vw;
    margin-bottom: 0.4vw;
  }
  .bk_b {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .leftData {
    display: flex;
    align-items: flex-end;
    .takeNumber {
      width: 100%;
      height: 22vh;
      box-sizing: border-box;
      padding: 1.5vh 0.8vw;
      margin-top: 2vh;
      border: 0.07vw solid rgb(30, 30, 230);
      border-radius: 0.2vw;
      header {
        font-size: 1.7vh;
        font-family: PingFang SC;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
      }
      .electricity {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > div {
          margin-top: 0.5vh;
          width: 48%;
          // padding-top: 0.2vh;
          line-height: 2.2vh;
          height: 5.8vh;
          // background:linear-gradient(to right #00a2f7 30%,#023f87);
          // background: linear-gradient(to right, #0a6aa1 30%, #5092dd);
            background-color:  #87ceeb;
          background: linear-gradient(to right, #87ceeb 30%, #0a6aa1a8);
          border-radius: 0.2vw;
          text-align: center;
          font-family: PingFang SC;
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
          span {
            //  padding-top: 0.2vh;
            font-size: 1.5vh;
            line-height: 2vh;
          }
          .Num span {
            font-size:2.2vh;
            line-height: 2.5vh;
          }
        }
      }
      .customer {
        width: 100%;
        margin-top: 1vh;
        text-align: center;
        font-family: PingFang SC;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        height: 8.2vh;
        background: rgb(0, 184, 181);
        border-radius: 0.2vw;
        span {
          font-size: 1.8vh;
          line-height: 3vh;
        }
        p span {
          font-size: 2vh;
          line-height: 4vh;
        }
      }
    }
    .sosAlarm {
      width: 100%;
      height: 35vh;
      // flex: 1;
      box-sizing: border-box;
      padding-top: 1vh;
      border: 0.07vw solid rgb(30, 30, 230);
      border-radius: 0.2vw;
      margin-top: 2vh;
      // margin-bottom: 1.5vh;
      overflow: hidden;
      .alarmCont {
        width: 100%;
        height: 100%;
        .storesDetails {
          display: flex;
          justify-content: flex-start;
          height: 100%;
          border-top: 0.07vw solid skyblue;
          .storesName {
            width: 70%;
            font-size: 1.3vh;
            font-family: PingFang SC;
            color: rgba(255, 255, 255, 1);
            height: 100%;
            border-right: 0.07vw solid skyblue;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1vh;
          }
          .detailsListCont {
            width: 100%;
            position: relative;
            overflow-y: auto;
            > div {
              > div {
                text-align: center;
                line-height: 2.5vh;
                font-family: PingFang SC;
                color: rgba(255, 255, 255, 1);
                font-size: 1.3vh;
                border-bottom: 0.07vw solid skyblue;
                cursor: pointer;
              }
            }
            img {
              width: 30%;
              // margin: 0 auto ;
              // display: flex;
              // align-items: center;
              // align-items: center;
              position: absolute;
              top: 30%;
              left: 30%;
              transform: translateY(-30%, -30%);
            }
          }
        }
        .listheader,
        .list {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-family: PingFang SC;
          color: rgba(255, 255, 255, 1);
          padding: 0 15px;
          box-sizing: border-box;
          h5 {
            font-weight: bold;
            font-size: 1.3vh;
          }
          span {
            font-size: 1.1vh;
          }
        }
        .list {
          line-height: 2.5vh;
          cursor: pointer;
          &:nth-child(odd) {
            background: #15284a;
          }
          p {
            font-size: 1.1vh;
          }
          span {
            display: inline-block;
            background: red;
            min-width: 1.8vh;
            height: 1.8vh;
            text-align: center;
            border-radius: 50%;
            line-height: 1.8vh;
          }
        }
      }
    }
    .degreeCont {
      width: 100%;
      height: 130px;
      margin-top: 2vh;
      box-sizing: border-box;
      padding: 8px;
      padding-left: 15px;
      border: 0.07vw solid rgb(30, 30, 230);
      border-radius: 5px;
      .temperature {
        margin-top: 8px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        p {
          font-family: PingFang SC;
          color: rgba(255, 255, 255, 1);
          font-size: 1.1vh;
        }
        .temperatureList {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 80%;
          height: 25px;
          margin-left: 10px;
          h4 {
            width: 2px;
            height: 100%;
            background: blue;
            border-radius: 5px;
            margin-right: 5px;
          }
          .red {
            background: red;
          }
        }
      }
    }
    .takeNumber,
    .sosAlarm,
    .degreeCont {
      width: 90%;
    }
  }
  .rightData {
    min-width: 22%;
    .enterDetails {
      margin-top: 2vh;
      width: 90%;
      height: 56vh;
      box-sizing: border-box;
      border: 0.07vw solid rgb(30, 30, 230);
      border-radius: 0.2vw;
      margin-bottom: 2vh;
     
      header {
        font-size: 1.3vh;
        font-family: PingFang SC;
        color: rgba(255, 255, 255, 1);
        line-height: 4vh;
        padding-left: 15px;
      }
      > div {
        padding: 0 0.82vw;
      }
      > .enterDetalis_title{
         padding-top: 1vh;
         font-size: 2vh;
         padding-bottom: 1vh;
      }
      .sorting {
        line-height: 2.5vh;
        font-family: PingFang SC;
        color: rgba(255, 255, 255, 1);
        border-bottom: 0.07vw solid #87ceeb;
        margin-bottom: 0.5vh;
        span {
          font-size: 1.1vh;
        }
      }
      .alarm_panel {
        height: calc(100% - 6vh);
        line-height: 1.5vh;
        padding: 0;
      }
      .enterList {
        display: flex;
        padding: 0 0.7vw;
        justify-content: space-between;
        align-items: center;
        line-height: 2.5vh;
        font-family: PingFang SC;
        color: rgba(255, 255, 255, 1);
        &:nth-child(odd) {
          background: #15284a;
        }
      }
      p,
      span {
        font-size: 1.1vh;
      }
    }
    .probability {
      width: 90%;
      height: 38vh;
      margin-top: 2vh;
      padding: 1.5vh 1vw ;
      box-sizing: border-box;
      border: 0.07vw solid rgb(30, 30, 230);
      border-radius: 0.2vw;
      position: relative;
      
    }
    .title {
      // padding: 15px;
      display: flex;
      justify-content: space-between;
      height: 6.2vh;
      margin-bottom: 1.5vh;
      > div {
        width: 48%;
        font-family: PingFang SC;
        color: rgba(255, 255, 255, 1);
        border-radius: 0.2vw;
        text-align: center;
        .pmsFont{
          padding-top: 0.2vh;
          font-size: 1.2vh;
          line-height: 2vh;
          height: 2vh;
        }
        .countNum{
          height: 4vh;
            line-height: 4vh;
            font-size:3vh;
        }
      }
    }
    // header {
    //   font-size: 1.3vh;
    //   font-family: PingFang SC;
    //   font-weight: bold;
    //   color: white;
    //   margin-left: 15px;
    // }
    .line {
      width: 100%;
      height: 27vh;
      box-sizing: border-box;
      #lineEcharts {
        width: 100%;
        height: 100%;
      }
    }
  }
}

// 出租率情况
.enterDetails {
  color: #fff;
  overflow: hidden;
  .title {
    margin-top: 10px;
  }
  .enter_btn {
    margin-top: 0.5vh;
  }
  .btn {
    border: 0.07vw solid #ccc;
    border-radius: 0.2vw;
    padding: 0.5vh 0.2vw;
  }
  .asc,
  .desc {
    width: 0;
    height: 0;
    border-width: 0 1vh 1vh;
    border-style: solid;
    border-color: transparent transparent #ccc;
    cursor: pointer;
  }
  .asc {
    &.active {
      border-bottom-color: red;
    }
  }
  .desc {
    border-bottom: 0;
    border-top-width: 1vh;
    border-top-color: #ccc;
    &.active {
      border-top-color: red;
    }
  }
}
.exter_facility,
.exter_PMS {
  // flex-basis:4vw;
  width: 3vw;
  min-width: 3vw;
}
.enter_panel {
  height: calc(100% - 10.5vh);
  overflow-y: auto;
  font-size: 1.3vh;
  padding-top: 1vh;
  // margin-bottom: 50px;
}
.exter_shop {
  padding: 0 0.9vw;
  font-size: 1.1vh;
  // white-space: nowrap;
}
.enter_cell {
  text-align: center;
  align-items: center;
  padding: 0.3vh 0;
  border-bottom: 0.07vw solid #87ceeb;
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home" }, [
    _c("ul", { staticClass: "flexContent flex" }, [
      _c("li", { staticClass: "leftData flex flex-column" }, [
        _c("div", { staticClass: "takeNumber bk_b" }, [
          _c("header", [_vm._v("取电房间数量")]),
          _c("div", { staticClass: "electricity" }, [
            _c("div", { staticClass: "take_ins" }, [
              _c("span", [_vm._v("内部取电")]),
              _c(
                "div",
                { staticClass: "Num" },
                [
                  _c("count-to", {
                    attrs: {
                      "start-val": _vm.initElectricity.manage,
                      "end-val": _vm.roomElectricity.manage,
                      duration: 3000,
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "take_total" }, [
              _vm._m(0),
              _c(
                "div",
                { staticClass: "Num" },
                [
                  _c("count-to", {
                    attrs: {
                      "start-val": _vm.initTotal,
                      "end-val": _vm.total,
                      duration: 3000,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "customer flex flex-column" },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "2vh", "line-height": "3.5vh" } },
                [_vm._v("客人取电")]
              ),
              _c("count-to", {
                staticStyle: { "font-size": "3.5vh" },
                attrs: {
                  "start-val": _vm.initElectricity.guest,
                  "end-val": _vm.roomElectricity.guest,
                  duration: 3000,
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "sosAlarm bk_b" }, [
          _c("div", { staticClass: "alarmCont" }, [
            _c(
              "div",
              {
                staticClass: "listheader",
                staticStyle: { "line-height": "3.2vh" },
              },
              [
                _c("h5", [_vm._v("SOS报警")]),
                _c("span", [_vm._v("合计：" + _vm._s(_vm.storesTotal))]),
              ]
            ),
            _c("div", { staticClass: "alarm_panel" }, [
              _vm.sos
                ? _c(
                    "div",
                    { staticClass: "listcount" },
                    [
                      _vm.stores.length
                        ? _vm._l(_vm.stores, function (storesItem, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "list",
                                on: {
                                  click: function ($event) {
                                    return _vm.storesClick(storesItem)
                                  },
                                },
                              },
                              [
                                _c("p", [_vm._v(_vm._s(storesItem.hotelName))]),
                                _c("span", [_vm._v(_vm._s(storesItem.count))]),
                              ]
                            )
                          })
                        : [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "noData flex flex-center align-center",
                              },
                              [_vm._v("暂无 SOS 报警")]
                            ),
                          ],
                    ],
                    2
                  )
                : _c("div", { staticClass: "storesDetails" }, [
                    _c("div", { staticClass: "storesName" }, [
                      _vm._v(_vm._s(_vm.storesName)),
                    ]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.sosFlag,
                            expression: "sosFlag",
                          },
                        ],
                        staticClass: "detailsListCont",
                      },
                      [
                        !_vm.sosFlag
                          ? _c(
                              "div",
                              _vm._l(_vm.roomList, function (roomItem, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    on: {
                                      click: function ($event) {
                                        _vm.sos = true
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(roomItem.floorName) +
                                        _vm._s(roomItem.roomNumber) +
                                        "房"
                                    ),
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "sosAlarm bk_b" }, [
          _c("div", { staticClass: "alarmCont" }, [
            _c(
              "div",
              {
                staticClass: "listheader",
                staticStyle: { "line-height": "3.2vh" },
              },
              [
                _c("h5", [_vm._v("设备离线报警")]),
                _c("span", [_vm._v("合计：" + _vm._s(_vm.offlineStoresTotal))]),
              ]
            ),
            _c("div", { staticClass: "alarm_panel" }, [
              _vm.offline
                ? _c(
                    "div",
                    { staticClass: "ListCont" },
                    [
                      _vm.offlineStores.length
                        ? _vm._l(
                            _vm.offlineStores,
                            function (offlineItem, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "list",
                                  on: {
                                    click: function ($event) {
                                      return _vm.offlineClick(offlineItem)
                                    },
                                  },
                                },
                                [
                                  _c("p", [
                                    _vm._v(_vm._s(offlineItem.hotelName)),
                                  ]),
                                  _c("span", [
                                    _vm._v(_vm._s(offlineItem.count)),
                                  ]),
                                ]
                              )
                            }
                          )
                        : [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "noData flex flex-center align-center",
                              },
                              [_vm._v("暂无 设备离线 报警")]
                            ),
                          ],
                    ],
                    2
                  )
                : _c("div", { staticClass: "storesDetails" }, [
                    _c("div", { staticClass: "storesName" }, [
                      _vm._v(_vm._s(_vm.offlineName)),
                    ]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.offlineFlag,
                            expression: "offlineFlag",
                          },
                        ],
                        staticClass: "detailsListCont",
                      },
                      [
                        !_vm.offlineFlag
                          ? _c(
                              "div",
                              _vm._l(
                                _vm.offlineRoomList,
                                function (roomItem, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      on: {
                                        click: function ($event) {
                                          _vm.offline = true
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(roomItem.floorName) +
                                          _vm._s(roomItem.roomNumber) +
                                          "房"
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]),
            ]),
          ]),
        ]),
      ]),
      _c("li", { staticClass: "eachartsCont " }, [
        _c("div", { attrs: { id: "myChart" } }),
        _c(
          "div",
          { staticClass: " bar_charts" },
          [
            _c("div", { staticClass: "flex bar_tool flex-between" }, [
              _c("a", { staticClass: "shop" }, [
                _vm._v("分店数 " + _vm._s(_vm.brandRoom.sumBranchNumber)),
              ]),
              _c("a", { staticClass: "room" }, [
                _vm._v("客房数 " + _vm._s(_vm.brandRoom.sumRoomNumber)),
              ]),
            ]),
            _c("bar", { attrs: { list: _vm.brandRoom.brandRegionNumberVos } }),
          ],
          1
        ),
      ]),
      _c("li", { staticClass: "rightData flex flex-column" }, [
        _c("div", { staticClass: "probability bk_b" }, [
          _c("div", { staticClass: "title" }, [
            _c("div", { staticClass: "rent" }, [
              _vm._m(1),
              _c(
                "div",
                { staticClass: "countNum" },
                [
                  _c("count-to", {
                    staticStyle: { color: "#00CC00" },
                    attrs: {
                      "start-val": _vm.initDevicePMS.device,
                      "end-val": _vm.devicePMS.device || 0,
                      duration: 3000,
                      suffix: "%",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "div",
              {
                staticStyle: {
                  background: "linear-gradient(to right, #fe7b54 30%, #bf1d13)",
                },
              },
              [
                _vm._m(2),
                _c(
                  "div",
                  { staticClass: "countNum" },
                  [
                    _c("count-to", {
                      staticStyle: { color: "yellow" },
                      attrs: {
                        suffix: "%",
                        "start-val": _vm.initDevicePMS.pms,
                        "end-val": _vm.devicePMS.pms || 0,
                        duration: 3000,
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _vm._m(3),
        ]),
        _c("div", { staticClass: "enterDetails flex-1 bk_b" }, [
          _c("div", { staticClass: "enterDetalis_title" }, [
            _vm._v("\n          出租率情况\n          "),
          ]),
          _c("div", { staticClass: "flex flex-between" }, [
            _c("div", { staticClass: "flex flex-column" }, [
              _c("span", { staticClass: "btn" }, [_vm._v("设备出租率")]),
              _c("div", { staticClass: "flex enter_btn flex-center" }, [
                _c("div", {
                  staticClass: "asc",
                  class: {
                    active:
                      _vm.condition === "equipmentOccupancyRate" &&
                      _vm.order === "asc",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.getOccupancyInfo({
                        condition: "equipmentOccupancyRate",
                        order: "asc",
                      })
                    },
                  },
                }),
                _c("div", {
                  staticClass: "desc",
                  class: {
                    active:
                      _vm.condition === "equipmentOccupancyRate" &&
                      _vm.order === "desc",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.getOccupancyInfo({
                        condition: "equipmentOccupancyRate",
                        order: "desc",
                      })
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "flex flex-column" }, [
              _c("span", { staticClass: "btn" }, [_vm._v("PMS出租率")]),
              _c("div", { staticClass: "flex enter_btn flex-center" }, [
                _c("div", {
                  staticClass: "asc",
                  class: {
                    active:
                      _vm.condition === "pmsOccupancyRate" &&
                      _vm.order === "asc",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.getOccupancyInfo({
                        condition: "pmsOccupancyRate",
                        order: "asc",
                      })
                    },
                  },
                }),
                _c("div", {
                  staticClass: "desc",
                  class: {
                    active:
                      _vm.condition === "pmsOccupancyRate" &&
                      _vm.order === "desc",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.getOccupancyInfo({
                        condition: "pmsOccupancyRate",
                        order: "desc",
                      })
                    },
                  },
                }),
              ]),
            ]),
          ]),
          _vm.occupancyInfo.length
            ? _c(
                "div",
                { staticClass: "enter_panel flex-1" },
                _vm._l(_vm.occupancyInfo, function (info, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "flex enter_cell" },
                    [
                      _c("div", { staticClass: "exter_facility" }, [
                        _vm._v(_vm._s(info.equipmentOccupancyRate) + " %"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "exter_shop flex-1 text-center" },
                        [_vm._v(_vm._s(info.hotelName))]
                      ),
                      _c("div", { staticClass: "exter_PMS" }, [
                        _vm._v(_vm._s(info.pmsOccupancyRate) + " %"),
                      ]),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("span", [_vm._v("取电总数")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pmsFont" }, [
      _c("span", [_vm._v("设备出租率")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pmsFont" }, [
      _c("span", [_vm._v("PMS出率")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "line" }, [
      _c("div", { attrs: { id: "lineEcharts" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
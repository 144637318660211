/*
 * @Descripttion: 
 * @version: 0.0.1
 * @Author: 小妖怪
 * @Date: 2020-07-06 09:36:22
 * @LastEditors: Mr.小妖怪
 * @LastEditTime: 2020-07-08 21:29:13
 */ 
let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
var option = {
  getOptions(data) { 
      return {
          title: {
              text: '日出租率与日PMS',
              textStyle: {
                  align: 'left',  //字体居中
                  color: '#fff',  //字体颜色
                  fontSize: flag?'1.2vh':16,  //字体大小
               },
          },
          tooltip: {
              trigger: 'axis',
          },
          grid: {
            left: '5%',
            right: '6%',
            bottom: '0%',
            top: '20%',
              containLabel: true
          },
          xAxis: {
              type: 'category',
              boundaryGap: false,
              data: data.day, //['1','2','3','4','5','6' ],
              axisLabel: {
                  color: '#fff',
                  fontSize: flag?'0.5vh':10, 
                },
               
          },
          yAxis: {
              type: 'value',
              scale:true,
              // max:5000000,
              axisLabel: {
                fontSize: flag?'0.5vh':10, 
                  color: '#fff',
                },
          },
          series: [
              {
                  name: '设备出租率',
                  type: 'line',
                  // stack: '总量',
                  data: data.dayRented,
                  itemStyle: {
                      color: '#00CC00',
                      fontSize: flag?'0.5vh':10, 
                    },
              },
              {
                  name: 'PMS出租率',
                  type: 'line',
                  // stack: '总量',
                  data: data.dayPMS,
                  itemStyle: {
                      color: 'yellow',
                      fontSize: flag?'0.5vh':10, 
                    },
              }
          ]
      }
  }
};
export default option

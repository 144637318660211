<!--
 * @Descripttion:
 * @version: 0.0.1
 * @Author: 小妖怪
 * @Date: 2020-06-30 18:38:01
 * @LastEditors: Mr.小妖怪
 * @LastEditTime: 2020-07-07 10:13:38
-->
<template>
  <div class="homeBar">
    <div id="bar_horizontal"></div>
  </div>
</template>

<script>
import { getConfig } from "./config";
export default {
  name: "Home",
  props: {
    list: { type: Array, default: () => [] }
  },
  computed: {
    option: function() {
      return getConfig.call(this);
    }
  },
  data() {
    return {
      area: [],
      room: [],
      brands: []
    };
  },
  mounted() {
    this.myChart = this.$echarts.init(
      document.getElementById("bar_horizontal")
    );
    window.addEventListener("resize", () => {
      this.myChart.resize();
    });
    this.$nextTick(() => {
      setTimeout(() => {
        this.init();
      }, 1000);
    });
  },
  methods: {
    getMax(list){
      let max=Math.max.apply(null,this.brands) 
      console.log(max,this.brands);

    },
    init() {
      // 处理数据
      this.list.forEach(item => {
        this.area.push(item.region);
        this.room.push(item.rooms);
        this.brands.push(item.branchNumber);
      });
      // this.getMax()
      this.myChart.setOption(this.option);
    }
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  list-style: none;
}
.homeBar{
  width: 100%;
  height: 100%;
  
}
#bar_horizontal {
  width: 100%;
  height: calc(100% - 5vh);
  // height: calc(100%-32px);
  // height: 22vh;
  margin: auto;
}
</style>

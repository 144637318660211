
    import { title_Font,x_Font,y_Font,w_Font ,h_Font,line_Font,symbolSize_Font,map_Font,map_period,map_scale,map_shadowBlur,mapTip} from "../font";

function getConfig() {

  var top10CityList = this.area

  const option = {
    grid: {
      left: '10%',
      right: '10%',
      bottom: '10%',
      top: '5%',
      // containLabel: true
    },
    
    tooltip: { //提示框组件
      trigger: 'axis',
      formatter: '{b}<br />{a0}: {c0}<br />{a1}: {c1}',
      axisPointer: {
        type: 'shadow',
        label: {
          backgroundColor: '#6a7985'
        }
      },
      textStyle: {
        color: '#fff',
        fontStyle: 'normal',
        fontFamily: '微软雅黑',
        fontSize: x_Font(),
      }
    },
    yAxis: [{
      type: 'category',
      inverse: true,
      axisLabel: {
        show: true,
        textStyle: {
          color: '#fff',
          fontSize: x_Font(),
        }
      },
      splitLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLine: {
        show: false
      },
      data: top10CityList
    }
    ],
    xAxis: {
      type: 'value',
      axisTick: {
        show: false
      },
      axisLine: {
        show: false
      },
      splitLine: {
        show: false
      },
      axisLabel: {
        show: false
      }
    },
    xAxis: [
      {
        type: 'value',
        name: '客房数',
        show:false,
        // axisTick: {
        //   show: false
        // },
        // axisLine: {
        //   show: false
        // },
        // splitLine: {
        //   show: false
        // },
        // axisLabel: {
        //   show: false
        // }

      },
      {
        type: 'value',
        show:false,
        name: '分店数',
        max: getMax(this.brands)*2,
        // axisTick: {
        //   show: false
        // },
        // axisLine: {
        //   show: false
        // },
        // splitLine: {
        //   show: false
        // },
        // axisLabel: {
        //   show: false
        // }
    
      }
    ],
    series: [
      //     {
      //       data: [120, 200, 150, 80, 70, 110, 130],
      //       symbolPosition: 'start',
      //       type: 'pictorialBar',
      //           position: 'right',
      //         symbol: 'path://m0.50249,1.45085l315.27276,0l262.72726,200.50001l-262.72726,200.49999l-315.27276,0l0,-401z',
      //     },
      {
        z: 1.5,
        name: '客房数',
        type: 'bar',
        data: this.room,
        label: {
          normal: {
            color: '#fff',
            textStyle: {
              fontSize: x_Font(),
            },
            position: 'right',
            show: true
            // formatter: function(a, b) {
            //   return a.name
            // }
          }
        },
        itemStyle: {
          color: '#F59A23',
          fontSize: x_Font(),
          
        },
        // emphasis: {
        //   itemStyle: {
        //     color: new echarts.graphic.LinearGradient(
        //       0, 0, 0, 1,
        //       [
        //         { offset: 0, color: 'rgb(160, 207, 255)' },
        //         { offset: 1, color: 'rgb(83, 168, 255)' }
        //       ]
        //     )
        //   }
        // },
      },
      {
        z: 1.5,
        name: '分店数',
        type: 'bar',
        xAxisIndex:1,
        data: this.brands,
        label: {
          normal: {
            color: '#fff',
            textStyle: {
              fontSize: x_Font(),
              // x_Font(),
            },
            position: 'right',
            show: true,
            // formatter: function(a, b) {
            //   return a.value/20
            // }
          }
        },

        itemStyle: {
          color: '#CAF982'
        },
      }
    ],
    animationEasing: 'cubicOut'
  }
  return option
}

function  getMax(list){
  let maxNum= Math.max(...list) 
  return maxNum
}
export {
  getConfig
}
